import { graphql, useStaticQuery } from "gatsby"
import React, { useEffect, useState } from "react"
import { useAccordionToggle } from "react-bootstrap"
import { Footer } from "../components/Footer"
import { Header } from "../components/Header"
import "../css"
import "../scss/index.scss"
import locales from "../translate/locales"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import CollapseItem from "../components/CollapseItem"

const coachingAcademy = (props) => {
  useEffect(() => {
    document.body.parentElement!.className += `locale-${locale}`
  }, [])

  const locale = props.pageContext.locale
  const texts = locales[locale as "en"]

  const translate = texts.onlineAcademyPageContent

  // TODO filter for the lang content
  const pageData = useStaticQuery(graphql`
    query {
      allContentfulOnlineAcademyCourses {
        edges {
          node {
            id
            title
            image {
              file {
                url
              }
            }
            shortDescription
            courseUrl

            category
            price
          }
        }
      }

      allContentfulOnlineTrainingAcademyPage {
        edges {
          node {
            introTitleAr
            introTitleEn
            introTopSectionTextEn {
              childMarkdownRemark {
                html
              }
            }

            introTopSectionTextAr {
              childMarkdownRemark {
                html
              }
            }

            introBottomSectionTextEn {
              childMarkdownRemark {
                html
              }
            }

            introBottomSectionTextAr {
              childMarkdownRemark {
                html
              }
            }

            ourSponsorsSection {
              logo {
                file {
                  url
                }
              }
            }

            askedQuestions {
              questionTitleAr
              questionTitleEn
              answerAr {
                json
              }

              answerEn {
                json
              }
            }
          }
        }
      }
    }
  `)

  const longText = (value: any) => {
    const result = value.childMarkdownRemark.html.split(/\<.*?\>/g)
    return result
  }

  const pageContent =
    pageData.allContentfulOnlineTrainingAcademyPage.edges[0].node

  const askedQuestions = pageContent.askedQuestions

  const [selectedItem, setSelectedItem] = useState<number | null>(null)

  return (
    <div>
      <Header lang={locale} />
      <div
        className="page-title bg_cover mt-80 pt-140 pb-140"
        style={{
          backgroundImage: `url(/images/pexels-photo-257897.jpeg)`,
          height: 500,
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="page-title-item text-center">
                <div className="page-title-item text-center">
                  <h3 className="title"> {translate.onlineAcademyTraing}</h3>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="#">{translate.home}</a>
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="access-area pt-120 pb-120">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="access-content">
                <div className="access-mission">
                  <h3 className="title">
                    {locale === "ar"
                      ? pageContent.introTitleAr
                      : pageContent.introTitleEn}
                  </h3>
                  <p>
                    {longText(
                      locale == "ar"
                        ? pageContent.introTopSectionTextAr
                        : pageContent.introTopSectionTextEn
                    )}
                  </p>
                </div>

                <p>
                  {longText(
                    locale == "ar"
                      ? pageContent.introBottomSectionTextAr
                      : pageContent.introBottomSectionTextEn
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-counter-area bg_cover pt-120 pb-120">
        <div className="container">
          <div className="about-counter-content">
            <div className="row">
              <div className="col-lg-4">
                <div className="top-Icon">
                  <i className="fas fa-tv" id="about-icon"></i>
                </div>
                <div className="box-title">
                  <span> {translate.onlineCoursesModel} </span>
                </div>

                <p>{translate.onlineCoursesModelContent}</p>
              </div>

              <div className="col-lg-4">
                <div className="top-Icon">
                  <i className="fab fa-connectdevelop" id="about-icon"></i>
                </div>
                <div className="box-title">
                  <span> {translate.building} </span>
                </div>
                <div className="text">
                  <p>{translate.buildingContent}</p>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="top-Icon">
                  <i className="far fa-folder" id="about-icon"></i>
                </div>
                <div className="box-title">
                  <span> {translate.buildingBags}</span>
                </div>
                <div className="text">
                  <p>{translate.buildingBagsContent}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="advance-courses-area pb-120 mt-200">
        <div className="container">
          <div className="row courses-active">
            {pageData.allContentfulOnlineAcademyCourses.edges.map(
              (course: any) => {
                return (
                  <div className="col-lg-4">
                    <div className="single-courses mt-30">
                      <div className="courses-thumb">
                        <img
                          src={course.node.image.file.url}
                          alt="courses"
                          height="200"
                        />
                        <div className="courses-review"></div>
                        <div className="corses-thumb-title">
                          <span>{course.node.category} </span>
                        </div>
                      </div>
                      <div className="courses-content">
                        <a
                          href={course.node.courseUrl}
                          style={{ minHeight: 175 }}
                        >
                          <h4 className="title">{course.node.title}</h4>
                          <span style={{ padding: "20px 0px", color: "#333" }}>
                            {course.node.shortDescription}
                          </span>
                          <div
                            className="courses-info d-flex justify-content-between"
                            style={{ bottom: 50 }}
                          >
                            <span style={{ position: "absolute", bottom: 40 }}>
                              ${course.node.price}
                            </span>
                          </div>
                        </a>
                        <div className="courses-info d-flex justify-content-between"></div>
                      </div>
                    </div>
                  </div>
                )
              }
            )}
          </div>
        </div>
      </section>

      <section className="core-features-area pt-110 pb-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-title section-title-2 text-center">
                <h4
                  className="title"
                  style={{ color: "rgba(13, 23, 48, 0.87)" }}
                >
                  {translate.askedQuestions}
                </h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="faq-accordion mt-30">
                <div className="accordion" id="accordionExample">
                  {askedQuestions.map((questions: any, idx: number) => {
                    const title =
                      locale == "ar"
                        ? questions.questionTitleAr
                        : questions.questionTitleEn

                    const contentJeson =
                      locale == "ar"
                        ? questions.answerAr.json
                        : questions.answerEn.json

                    const isSelectedItem = selectedItem === idx

                    return (
                      <CollapseItem
                        contentJeson={contentJeson}
                        index={idx}
                        title={title}
                        key={idx}
                        onSelect={(selIdx) => {
                          setSelectedItem(selIdx)
                        }}
                        isSelectedItem={isSelectedItem}
                      />
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="sponsors-area bg_cover pb-120"
        style={{ direction: "ltr" }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-title text-center  mb-40">
                <h3
                  className="title"
                  style={{ color: "rgba(13, 23, 48, 0.87)" }}
                >
                  {translate.ourSponsors}
                </h3>
              </div>
            </div>
          </div>
          <div className="row brand-active owl-carousel">
            {pageContent.ourSponsorsSection.map((sponsor: any) => {
              return (
                <div className="col-lg-12">
                  <div className="single-brand">
                    <img src={sponsor.logo.file.url} alt="brand" width="280" />
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row">
            <a
              className="main-btn"
              href="https://www.emasteryacademy.com/ar"
              id="visitSite"
            >
              <i className="fal fa-home"></i> {translate.goToSit}
            </a>
          </div>
        </div>
      </section>

      <Footer lang={locale} />
    </div>
  )
}

export default coachingAcademy
